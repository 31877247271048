const columns = [
        {
            title: '#',
            scopedSlots: { customRender: 'serial' }
        },
        {
            title: '菜单名称',
            dataIndex: 'permissionName',
            key: 'permissionName',
        },
        {
            title: '菜单路径',
            dataIndex: 'permissionUrl',
            key: 'permissionUrl',
            ellipsis: true,
        },
        {
            title: '图标',
            dataIndex: 'permissionIcon',
            key: 'permissionIcon',
            ellipsis: true,
        },
        {
            title: '创建时间',
            key: 'createTime',
            dataIndex: 'createTime',
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            width: 140,
            scopedSlots: { customRender: 'action' },
        },
    ];
export {
    columns
}
