<template>
    <a-modal
            :title="id?'修改菜单':'新增菜单'"
            :visible="visible"
            width="30%"
            :confirm-loading="confirmLoading"
            ok-text="确认"
            cancel-text="取消"
            @ok="handleConfirm"
            @cancel="handleCancel">
        <a-form :form="form">
            <a-form-item label="上级菜单" v-bind="formItemLayout">
                <a-select v-decorator="['parentId']"
                          :disabled="id?true: false"
                          placeholder="请选择上级菜单" allowClear>
                    <a-select-option v-for="item in menus" :key="item.id"
                                     :value="item.id">{{item.permissionName}}
                    </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="菜单名称" v-bind="formItemLayout">
                <a-input
                        placeholder="请输入菜单名称"
                        v-decorator="['permissionName',
            {rules: [{ required: true,max:20, message: '菜单名称必填、最大长度20个字符'}]}]"
                />
            </a-form-item>
            <a-form-item label="菜单地址" v-bind="formItemLayout">
                <a-input
                        placeholder="请输入菜单地址"
                        v-decorator="['permissionUrl',
            {rules: [{ required: true,max:50, message: '菜单地址必填、最大长度20个字符'}]}]"
                />
            </a-form-item>
            <a-form-item label="图标" v-bind="formItemLayout">
                <a-input
                        placeholder="请输入图标"
                        v-decorator="['permissionIcon']"
                />
            </a-form-item>
        </a-form>
    </a-modal>
</template>

<script>
    import {list,add,update} from '@/api/menus'
    export default {
        props:['visible'],
        data(){
            return {
                form: this.$form.createForm(this),
                formItemLayout: {
                    labelCol: {
                        span: 6
                    },
                    wrapperCol: {
                        span: 18
                    }
                },
                confirmLoading: false,
                menus:[],
                id:''
            }
        },
        computed: {
            show: {
                get: function (){
                    return this.visible;
                },
                set: function () {}
            }
        },
        watch:{
            visible(val){
                if(val) {
                    this.getParentmenus()
                }
            }
        },
        methods:{
            getParentmenus() {
                list({permissionTyp:'menu'}).then((res)=>{
                    this.menus = res
                })
            },
            setFormValues(record) {
                Object.keys(record).forEach(key => {
                    this.form.getFieldDecorator(key);
                    let obj = {};
                    if(key == "id") {
                        this.id = record[key]
                    }else {
                        obj[key] = record[key];
                        this.form.setFieldsValue(obj);
                    }
                });
            },
            handleConfirm(e) {
                this.confirmLoading = true;
                this.form.validateFields(async (err, values) => {
                    this.confirmLoading = false;
                    values.permissionType = 'menu'
                    if(!err) {
                        console.log(values)
                        if (!this.id) {
                            add(values).then((res) => {
                                this.form.resetFields()
                                this.$message.success('操作成功');
                                this.$emit('confirm')
                                this.$emit('close')
                            })
                        }else {
                            values.id = this.id
                            update(values).then((res) => {
                                this.id = ''
                                this.form.resetFields()
                                this.$message.success('操作成功');
                                this.$emit('confirm')
                                this.$emit('close')
                            })
                        }
                    }
                });
            },
            handleCancel() {
                this.$emit('close')
                this.id=''
                this.form.resetFields()
            }
        }
    }
</script>

<style scoped>

</style>